import React, { useEffect, useLayoutEffect, useState } from "react";
import type { AppState } from "ee/reducers";
import type { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { message as antd_message } from "antd";
import type { InjectedFormProps } from "redux-form";
import { reduxForm, Field } from "redux-form";
import { RESET_PASSWORD_FORM_NAME } from "ee/constants/forms";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import { getIsTokenValid, getIsValidatingToken } from "selectors/authSelectors";
import FormTextField from "components/utils/ReduxFormTextField";
import { Button, Callout, Link } from "design-system";
import Spinner from "components/editorComponents/Spinner";
import StyledForm from "components/editorComponents/Form";
import { isEmptyString, isStrongPassword } from "utils/formhelpers";
import type { ResetPasswordFormValues } from "./helpers";
import { resetPasswordSubmitHandler } from "./helpers";
import { FormActions, StyledFormGroup } from "./StyledComponents";
import { AUTH_LOGIN_URL, FORGOT_PASSWORD_URL } from "constants/routes";
import {
  RESET_PASSWORD_PAGE_PASSWORD_INPUT_LABEL,
  RESET_PASSWORD_PAGE_PASSWORD_INPUT_PLACEHOLDER,
  RESET_PASSWORD_LOGIN_LINK_TEXT,
  RESET_PASSWORD_SUBMIT_BUTTON_TEXT,
  RESET_PASSWORD_PAGE_TITLE,
  FORM_VALIDATION_INVALID_PASSWORD,
  FORM_VALIDATION_EMPTY_PASSWORD,
  RESET_PASSWORD_EXPIRED_TOKEN,
  RESET_PASSWORD_FORGOT_PASSWORD_LINK,
  RESET_PASSWORD_INVALID_TOKEN,
  RESET_PASSWORD_RESET_SUCCESS,
  RESET_PASSWORD_RESET_SUCCESS_LOGIN_LINK,
  createMessage,
  ALREADY_HAVE_AN_ACCOUNT,
  SIGNUP_PAGE_LOGIN_LINK_TEXT,
} from "ee/constants/messages";
import Container from "./Container";
import type { CalloutProps } from "design-system/build/Callout/Callout.types";
import {
  LoginForm,
  StyledEyeOffIcon,
  StyledEyeOnIcon,
} from "pages/UserAuth/Login";
import { FormBottom } from "./ForgotPassword"

const validate = (values: ResetPasswordFormValues) => {
  const errors: ResetPasswordFormValues = {};
  if (!values.password || isEmptyString(values.password)) {
    errors.password = createMessage(FORM_VALIDATION_EMPTY_PASSWORD);
  } else if (!isStrongPassword(values.password)) {
    errors.password = createMessage(FORM_VALIDATION_INVALID_PASSWORD);
  }
  return errors;
};

type ResetPasswordProps = InjectedFormProps<
  ResetPasswordFormValues,
  {
    verifyToken: (token: string) => void;
    isTokenValid: boolean;
    validatingToken: boolean;
  }
> & {
  verifyToken: (token: string) => void;
  isTokenValid: boolean;
  validatingToken: boolean;
} & RouteComponentProps<{ email: string; token: string }>;

export function ResetPassword(props: ResetPasswordProps) {
  const {
    error,
    handleSubmit,
    initialValues,
    isTokenValid,
    pristine,
    submitFailed,
    submitSucceeded,
    submitting,
    validatingToken,
    verifyToken,
  } = props;
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  useLayoutEffect(() => {
    // if (initialValues.token) verifyToken(initialValues.token);
  }, [initialValues.token, verifyToken]);

  const showInvalidMessage = !initialValues.token;
  const showExpiredMessage = !isTokenValid && !validatingToken;
  const showSuccessMessage = submitSucceeded && !pristine;
  const showFailureMessage = submitFailed && !!error;

  let condition = "";
  if (showExpiredMessage) condition = "expired";
  if (showInvalidMessage) condition = "invalid";
  if (showSuccessMessage) condition = "success";
  if (showFailureMessage) condition = "failure";

  // useEffect(() => {
  //   let message = "";
  //   switch (condition) {
  //     case "expired":
  //       message = createMessage(RESET_PASSWORD_EXPIRED_TOKEN);
  //       break;
  //     case "invalid":
  //       message = createMessage(RESET_PASSWORD_INVALID_TOKEN);
  //       break;
  //     case "success":
  //       message = createMessage(RESET_PASSWORD_RESET_SUCCESS);
  //       break;
  //     case "failure":
  //       message = error;
  //       break;
  //     default:
  //       break;
  //   }

  //   if (message !== "") {
  //     antd_message.open({
  //       type: showSuccessMessage ? "success" : "error",
  //       duration: 3,
  //       content: message,
  //       className: "my-msg",
  //     });
  //   }

  //   return () => {
  //     antd_message.destroy();
  //   };
  // }, [
  //   showSuccessMessage,
  //   showFailureMessage,
  //   showExpiredMessage,
  //   showInvalidMessage,
  // ]);

  // if (!isTokenValid && validatingToken) {
  //   return <Spinner />;
  // }
  return (
    <Container
      subtitle={
        <Link
          className="text-sm justify-center"
          startIcon="arrow-left-line"
          target="_self"
          to={AUTH_LOGIN_URL}
        >
          {createMessage(RESET_PASSWORD_LOGIN_LINK_TEXT)}
        </Link>
      }
      title={createMessage(RESET_PASSWORD_PAGE_TITLE)}
    >
      <StyledForm onSubmit={handleSubmit(resetPasswordSubmitHandler)}>
        <StyledFormGroup
          className="text-[color:var(--ads-v2\-color-fg)]"
          intent={error ? "danger" : "none"}
        >
          <LoginForm>
            <FormTextField
              className="pp-height"
              disabled={submitSucceeded}
              name="password"
              placeholder={createMessage(
                RESET_PASSWORD_PAGE_PASSWORD_INPUT_PLACEHOLDER,
              )}
              type={isShowPassword ? "text" : "password"}
            />
          </LoginForm>
          {isShowPassword ? (
            <StyledEyeOnIcon
              height={15}
              onClick={() => setIsShowPassword(false)}
              width={15}
            />
          ) : (
            <StyledEyeOffIcon
              height={15}
              onClick={() => setIsShowPassword(true)}
              width={15}
            />
          )}
        </StyledFormGroup>
        <Field component="input" name="email" type="hidden" />
        <Field component="input" name="token" type="hidden" />
        <FormActions className="pp-height pp-font">
          <Button
            isDisabled={pristine || submitSucceeded}
            isLoading={submitting}
            size="md"
            type="submit"
          >
            {createMessage(RESET_PASSWORD_SUBMIT_BUTTON_TEXT)}
          </Button>
        </FormActions>
      </StyledForm>
      {/* 底部提示 */}
      <FormBottom>
        {createMessage(ALREADY_HAVE_AN_ACCOUNT)}
        <Link
          className="t--sign-up t--signup-link pl-[var(--ads-v2\-spaces-3)] fs-16 a_link"
          kind="primary"
          target="_self"
          to={AUTH_LOGIN_URL}
        >
          {createMessage(SIGNUP_PAGE_LOGIN_LINK_TEXT)}
        </Link>
      </FormBottom>
    </Container>
  );
}

export default connect(
  (state: AppState, props: ResetPasswordProps) => {
    const queryParams = new URLSearchParams(props.location.search);
    return {
      initialValues: {
        token: queryParams.get("token") || undefined,
      },
      isTokenValid: getIsTokenValid(state),
      validatingToken: getIsValidatingToken(state),
    };
  },
  (dispatch: any) => ({
    verifyToken: (token: string) =>
      dispatch({
        type: ReduxActionTypes.RESET_PASSWORD_VERIFY_TOKEN_INIT,
        payload: { token },
      }),
  }),
)(
  reduxForm<
    ResetPasswordFormValues,
    {
      verifyToken: (token: string) => void;
      validatingToken: boolean;
      isTokenValid: boolean;
    }
  >({
    validate,
    form: RESET_PASSWORD_FORM_NAME,
    touchOnBlur: true,
  })(withRouter(ResetPassword)),
);
