import { flattenDSL } from "@shared/dsl";
import { safeCrashAppRequest } from "actions/errorActions";
import { ApiResponse } from "api/ApiResponses";
import ModuleEditorLoadApi from "api/loadModuleEditorApi";
import { FetchPageResponse, FetchPageResponseData } from "api/PageApi";
import { PluginFormPayload } from "api/PluginApi";
import { axiosConnectionAbortedCode } from "ce/api/ApiUtils";
import { FetchApplicationResponse } from "ce/api/ApplicationApi";
import {
  ReduxAction,
  ReduxActionTypes,
  UpdateCanvasPayload,
} from "ee/constants/ReduxActionConstants";
import { FeatureFlags, FEATURE_FLAG } from "ce/entities/FeatureFlag";
import { fetchFeatureFlags } from "ce/sagas/userSagas";
import { selectFeatureFlagCheck } from "ce/selectors/featureFlagsSelectors";
import { MAIN_CONTAINER_WIDGET_ID } from "constants/WidgetConstants";
import { Action, ActionViewMode } from "entities/Action";
import { APP_MODE } from "entities/App";
import { AppTheme } from "entities/AppTheming";
import { Datasource } from "entities/Datasource";
import { JSCollection } from "entities/JSCollection";
import { getLayoutSystemDSLTransformer } from "layoutSystems/common/utils/LayoutSystemDSLTransformer";
import { LayoutSystemTypes } from "layoutSystems/types";
import { MainCanvasReduxState } from "reducers/uiReducers/mainCanvasReducer";
import { ProductAlert } from "reducers/uiReducers/usersReducer";
import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import { getMainCanvasProps } from "selectors/editorSelectors";
import { getLayoutSystemType } from "selectors/layoutSystemSelectors";
import { getIsServerDSLMigrationsEnabled } from "selectors/pageSelectors";
import { extractCurrentDSL } from "utils/WidgetPropsUtils";
import { embedRedirectURL, validateResponse } from "./ErrorSagas";
import { clearEvalCache } from "./EvaluationsSaga";
import type { DSLWidget } from "WidgetProvider/constants";
import { get } from "lodash";
import { fetchSnapshotDetailsAction } from "actions/autoLayoutActions";
import {
  checkIfMigrationIsNeeded,
  setDataUrl,
} from "ee/sagas/PageSagas";
import { waitForWidgetConfigBuild } from "./InitSagas";
import {
  initCanvasLayout,
  saveLayout,
  fetchAllPageEntityCompletion,
} from "actions/pageActions";
import { generateAutoHeightLayoutTreeAction } from "actions/autoHeightActions";
import AppEngineFactory from "entities/Engine/factory";
import type AppEngine from "entities/Engine";
import { AppEngineApiError } from "entities/Engine";
import {
  executePageLoadActions,
  fetchActions,
} from "actions/pluginActionActions";
import {
  updateAppStore,
  fetchPageSuccess,
  updateCurrentPage,
} from "actions/pageActions";
import { ReduxActionErrorTypes } from "ee/constants/ReduxActionConstants";

export interface DeployConsolidatedApi {
  productAlert: ApiResponse<ProductAlert>;
  tenantConfig: ApiResponse;
  featureFlags: ApiResponse<FeatureFlags>;
  userProfile: ApiResponse;
  pages: FetchApplicationResponse;
  publishedActions: ApiResponse<ActionViewMode[]>;
  publishedActionCollections: ApiResponse<JSCollection[]>;
  customJSLibraries: ApiResponse;
  pageWithMigratedDsl: FetchPageResponse;
  currentTheme: ApiResponse<AppTheme[]>;
  themes: ApiResponse<AppTheme>;
  module: any;
}
export interface EditConsolidatedApi {
  productAlert: ApiResponse<ProductAlert>;
  tenantConfig: ApiResponse;
  featureFlags: ApiResponse<FeatureFlags>;
  userProfile: ApiResponse;
  pages: FetchApplicationResponse;
  publishedActions: ApiResponse<ActionViewMode[]>;
  publishedActionCollections: ApiResponse<JSCollection[]>;
  customJSLibraries: ApiResponse;
  pageWithMigratedDsl: FetchPageResponse;
  currentTheme: ApiResponse<AppTheme[]>;
  themes: ApiResponse<AppTheme>;
  datasources: ApiResponse<Datasource[]>;
  pagesWithMigratedDsl: ApiResponse<FetchPageResponseData[]>;
  plugins: ApiResponse<Plugin[]>;
  mockDatasources: ApiResponse;
  pluginFormConfigs: ApiResponse<PluginFormPayload>[];
  unpublishedActions: ApiResponse<Action[]>;
  unpublishedActionCollections: ApiResponse<JSCollection[]>;
  module: any;
}
export type InitConsolidatedApi = DeployConsolidatedApi | EditConsolidatedApi;

function* moduleEngineSaga(action: ReduxAction<any>) {
  yield race({
    task: call(startModuleEngine, action),
    cancel: take(ReduxActionTypes.RESET_MODULE_EDITOR_REQUEST),
  });
}

function* isConsolidatedFetchFeatureFlagEnabled() {
  yield call(fetchFeatureFlags);

  const consolidatedApiFetch: boolean = yield select(
    selectFeatureFlagCheck,
    FEATURE_FLAG.rollout_consolidated_page_load_fetch_enabled,
  );
  return consolidatedApiFetch;
}

export function* getInitResponses({
  moduleId,
  mode,
  shouldInitialiseUserDetails,
}: {
  moduleId: string;
  branch?: string;
  mode?: APP_MODE;
  shouldInitialiseUserDetails?: boolean;
}): any {
  const params = {
    moduleId,
  };
  let response: InitConsolidatedApi | undefined;
  const isConsolidatedApiFetchEnabled = yield call(
    isConsolidatedFetchFeatureFlagEnabled,
  );
  if (!!isConsolidatedApiFetchEnabled) {
    try {
      //   yield call(
      //     executeActionDuringUserDetailsInitialisation,
      //     ReduxActionTypes.START_CONSOLIDATED_PAGE_LOAD,
      //     shouldInitialiseUserDetails,
      //   );
      const initConsolidatedApiResponse: ApiResponse<InitConsolidatedApi> =
        yield mode === APP_MODE.EDIT
          ? ModuleEditorLoadApi.getModuleEditorLoadDataEdit(params)
          : ModuleEditorLoadApi.getModuleEditorLoadDataView(params);
      const isValidResponse: boolean = yield validateResponse(
        initConsolidatedApiResponse,
      );
      response = initConsolidatedApiResponse.data;
      if (!isValidResponse) {
        // its only invalid when there is a axios related error
        throw new Error("Error occured " + axiosConnectionAbortedCode);
      } else {
        yield put({
          type: ReduxActionTypes.INITIALIZE_MODULE_EDITOR_SUCCESS,
        });
      }
    } catch (e: any) {
      // when the user is an anonymous user we embed the url with the attempted route
      // this is taken care in ce code repo but not on ee
      if (e?.response?.status === 401) {
        embedRedirectURL();
      }
      //   yield call(
      //     executeActionDuringUserDetailsInitialisation,
      //     ReduxActionTypes.END_CONSOLIDATED_PAGE_LOAD,
      //     shouldInitialiseUserDetails,
      //   );
    }
  }
  const { featureFlags, productAlert, tenantConfig, userProfile, ...rest } =
    response || {};
  //actions originating from INITIALIZE_CURRENT_PAGE should update user details
  //other actions are not necessary
  if (!shouldInitialiseUserDetails) {
    return rest;
  }
  //   yield put(getCurrentUser(userProfile));
  //   // we already fetch this feature flag when isConsolidatedApiFetchEnabled is true
  //   // do not fetch this again
  //   if (isConsolidatedApiFetchEnabled) {
  //     yield put(fetchFeatureFlagsInit(featureFlags));
  //   }
  //   yield put(getCurrentTenant(false, tenantConfig));
  //   yield put(fetchProductAlertInit(productAlert));
  //   yield call(
  //     executeActionDuringUserDetailsInitialisation,
  //     ReduxActionTypes.END_CONSOLIDATED_PAGE_LOAD,
  //     shouldInitialiseUserDetails,
  //   );
  return rest;
}

export const getCanvasWidgetsPayload = (
  pageResponse: FetchPageResponse,
  dslTransformer?: (dsl: DSLWidget) => DSLWidget,
  migrateDSLLocally: boolean = true,
): any => {
  const extractedDSL = get(pageResponse, "data.layout.dsl", {});
  const flattenedDSL = flattenDSL(extractedDSL);
  const pageWidgetId = MAIN_CONTAINER_WIDGET_ID;
  return {
    pageWidgetId,
    currentPageName: pageResponse.data.name,
    dsl: extractedDSL,
    widgets: flattenedDSL,
    currentLayoutId: get(pageResponse, "data.layout.id", ""),
    currentModuleId: get(pageResponse, "data.id", ""),
    // currentLayoutId: pageResponse.data.layouts[0].id, // TODO(abhinav): Handle for multiple layouts
    // currentApplicationId: pageResponse.data.applicationId,
    // pageActions: pageResponse.data.layouts[0].layoutOnLoadActions || [],
    // layoutOnLoadActionErrors:
    //   pageResponse.data.layouts[0].layoutOnLoadActionErrors || [],
  };
};

export function* handleFetchedModule({
  fetchPageResponse,
  isFirstLoad = false,
  moduleId,
}: {
  fetchPageResponse: any;
  moduleId?: string;
  isFirstLoad?: boolean;
}) {
  const layoutSystemType: LayoutSystemTypes = yield select(getLayoutSystemType);
  const mainCanvasProps: MainCanvasReduxState =
    yield select(getMainCanvasProps);
  const dslTransformer = getLayoutSystemDSLTransformer(
    layoutSystemType,
    mainCanvasProps.width,
  );
  const isValidResponse: boolean = yield validateResponse(fetchPageResponse);
  // const willPageBeMigrated = checkIfMigrationIsNeeded(fetchPageResponse);
  // const lastUpdatedTime = getLastUpdateTime(fetchPageResponse);
  // const pageSlug = fetchPageResponse.data.slug;
  // const pagePermissions = fetchPageResponse.data.userPermissions;
  if (isValidResponse) {
    // Clear any existing caches
    yield call(clearEvalCache);
    // Set url params
    // yield call(setDataUrl);
    // Wait for widget config to be loaded before we can generate the canvas payload
    // yield call(waitForWidgetConfigBuild);
    // Get Canvas payload
    const isServerDSLMigrationsEnabled: boolean = yield select(
      getIsServerDSLMigrationsEnabled,
    );
    const canvasWidgetsPayload = getCanvasWidgetsPayload(
      fetchPageResponse,
      dslTransformer,
      !isServerDSLMigrationsEnabled,
    );
    yield put({
      type: ReduxActionTypes.INIT_MODULE_CANVAS_LAYOUT,
      payload: canvasWidgetsPayload,
    });
    // // Update the canvas
    yield put(initCanvasLayout(canvasWidgetsPayload));

    // fetch snapshot API
    yield put(fetchSnapshotDetailsAction());
    // set current page{ id: "605c435a91dea93f0eaf91ba", slug: "page-1" }
    // yield put(updateCurrentPage(pageId, pageSlug, pagePermissions));
    // dispatch fetch page success
    yield put(fetchPageSuccess());

    // /* Currently, All Actions are fetched in initSagas and on pageSwitch we only fetch page
    //  */
    // // Hence, if is not isFirstLoad then trigger evaluation with execute pageLoad action
    // if (!isFirstLoad) {
    // yield put(fetchAllPageEntityCompletion([executePageLoadActions()]));
    // }

    // // Sets last updated time
    // yield put(setLastUpdatedTime(lastUpdatedTime));

    // yield put({
    //   type: ReduxActionTypes.UPDATE_CANVAS_STRUCTURE,
    //   payload: canvasWidgetsPayload.dsl,
    // });

    // // Since new page has new layout, we need to generate a data structure
    // // to compute dynamic height based on the new layout.
    // yield put(generateAutoHeightLayoutTreeAction(true, true));

    // // If the type of the layoutSystem is ANVIL, then we need to save the layout
    // // This is because we have updated the DSL
    // // using the AnvilDSLTransformer when we called the getCanvasWidgetsPayload function
    // if (willPageBeMigrated || layoutSystemType === LayoutSystemTypes.ANVIL) {
    // yield put(saveLayout());
    // }
  }
}

export function* startModuleEngine(action: ReduxAction<any>) {
  try {
    const engine: AppEngine = AppEngineFactory.create(
      action.payload.mode,
      action.payload.mode,
    );
    engine.startPerformanceTracking();
    yield call(engine.setupEngine, action.payload);

    const isFirstLoad = true;
    const allResponses: InitConsolidatedApi = yield call(getInitResponses, {
      ...action.payload,
    });
    yield handleFetchedModule({
      fetchPageResponse: allResponses.module,
      moduleId: allResponses.module.data.id,
      isFirstLoad,
    });

    const { applicationId, toLoadPageId } = yield call(
      engine.loadAppData,
      action.payload,
      allResponses,
    );
    // yield call(engine.loadAppURL, toLoadPageId, action.payload.pageId);
    yield call(
      engine.loadAppEntities,
      toLoadPageId,
      applicationId,
      allResponses,
    );
    // yield call(engine.loadGit, applicationId);
    // yield call(engine.completeChore);
    // yield put(generateAutoHeightLayoutTreeAction(true, false));
    // engine.stopPerformanceTracking();
  } catch (e) {
    // if (e instanceof AppEngineApiError) return;
    yield put(safeCrashAppRequest(e));
  }
}

function* resetEditorSaga() {
  //   yield put(resetCurrentApplication());
  //   yield put(resetPageList());
  //   yield put(resetApplicationWidgets());
  //   yield put(resetRecentEntities());
  //   // Reset to edit mode once user exits editor
  //   // Without doing this if the user creates a new app they
  //   // might end up in preview mode if they were in preview mode
  //   // previously
  //   yield put(setPreviewModeAction(false));
  //   yield put(resetSnipingMode());
  //   yield put(setExplorerActiveAction(true));
  //   yield put(setExplorerPinnedAction(true));
  //   yield put(resetEditorSuccess());
  //   yield fork(resetDebuggerLogs);
}

export default function* watchInitModuleSagas() {
  yield all([
    takeLatest(ReduxActionTypes.INITIALIZE_MODULE_EDITOR, moduleEngineSaga),
    takeLatest(ReduxActionTypes.RESET_MODULE_EDITOR_REQUEST, resetEditorSaga),
  ]);
}
