import { CreateUserRequest, CreateUserResponse } from "ee/api/UserApi";
import {
  ReduxAction,
  ReduxActionErrorTypes,
  ReduxActionErrorTypes1,
  ReduxActionTypes,
  ReduxActionWithPromise,
} from "ee/constants/ReduxActionConstants";
import { ApiResponse } from "api/ApiResponses";
import { ImportModuleRequest } from "ce/actions/modulesActions";
import {
  ApplicationObject,
  ApplicationPagePayload,
  FetchUsersApplicationsWorkspacesResponse,
  WorkspaceApplicationObject,
} from "ce/api/ApplicationApi";
import { ModulesApi } from "ce/api/modulesApi";
import { isAirgapped } from "ce/utils/airgapHelpers";
import { isEmpty } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";
import {
  callAPI,
  getResponseErrorMessage,
  validateResponse,
} from "sagas/ErrorSagas";

interface AddFoldersAction {
  type: string;
  payload: {
    name: string;
    folderId?: string;
  };
}

export function* getFoldersSaga(action: {
  type: string;
  payload: { name: string };
}) {
  try {
    const response: FetchUsersApplicationsWorkspacesResponse = yield call(
      ModulesApi.getFolders,
      action.payload.name,
    );
    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.INIT_MODULES_FOLDERS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    // yield put({
    //   type: ReduxActionErrorTypes.INIT_MODULES_FOLDERS_ERROR,
    //   payload: {
    //     error,
    //   },
    // });
  }
}

export function* addFoldersSaga(action: AddFoldersAction) {
  try {
    const { payload } = action;
    const request = {
      name: payload.name,
    };
    const response: ApiResponse = yield call(ModulesApi.addFolders, request);
    const isValidResponse: boolean = yield call(validateResponse, response);
    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.ADD_FOLDER_SUCCESS,
      });
      yield put({
        type: ReduxActionTypes.INIT_MODULES_FOLDERS,
        payload: { name: "" },
      });
    }
  } catch (error: unknown) {
    yield put({
      type: ReduxActionErrorTypes1.ADD_FOLDER_ERROR,
      payload: { error },
    });
  }
}

export function* deleteFoldersSaga(action: {
  type: string;
  payload: { id: string };
}) {
  try {
    const { payload } = action;
    const request = {
      id: payload.id,
    };
    const response: ApiResponse = yield call(ModulesApi.deleteFolder, request);
    const isValidResponse: boolean = yield call(validateResponse, response);
    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.INIT_MODULES_FOLDERS,
        payload: { name: "" },
      });
    }
  } catch (error) {
    console.error("Error in addFoldersSaga:", error);
  }
}

export function* updateFoldersSaga(action: {
  type: string;
  payload: { name: string; id: string };
}) {
  try {
    const { payload } = action;
    const request = {
      id: payload.id,
      name: payload.name,
    };
    const response: ApiResponse = yield call(
      ModulesApi.updateFolderName,
      request,
    );
    const isValidResponse: boolean = yield call(validateResponse, response);
    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.INIT_MODULES_FOLDERS,
        payload: { name: "" },
      });
    }
  } catch (error) {
    // console.error("Error in addFoldersSaga:", error);
  }
}

export function* getModulesSaga(action: {
  type: string;
  payload: { name: string };
}) {
  try {
    const response: FetchUsersApplicationsWorkspacesResponse = yield call(
      ModulesApi.getModules,
      action.payload.name,
    );
    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.INIT_MODULES_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    // yield put({
    //   type: ReduxActionErrorTypes.INIT_MODULES_FOLDERS_ERROR,
    //   payload: {
    //     error,
    //   },
    // });
  }
}

export function* addModuleSaga(action: AddFoldersAction) {
  try {
    const { payload } = action;
    const request = {
      name: payload.name,
      folderId: payload.folderId,
    };
    const response: ApiResponse = yield call(ModulesApi.addModule, request);
    const isValidResponse: boolean = yield call(validateResponse, response);
    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.ADD_MODULE_SUCCESS,
      });
      if (payload.folderId) {
        yield put({
          type: ReduxActionTypes.INIT_FOLDER_MODULES,
          payload: { name: "", folderId: payload.folderId },
        });
      } else {
        yield put({
          type: ReduxActionTypes.INIT_MODULES,
          payload: { name: "" },
        });
      }
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes1.ADD_MODULE_ERROR,
      payload: { error },
    });
  }
}

export function* deleteModuleSaga(action: {
  type: string;
  payload: { id: string; folderId?: string };
}) {
  try {
    const { payload } = action;
    const request = {
      id: payload.id,
    };
    const response: ApiResponse = yield call(ModulesApi.deleteModule, request);
    const isValidResponse: boolean = yield call(validateResponse, response);
    if (isValidResponse) {
      if (isValidResponse) {
        if (payload.folderId) {
          yield put({
            type: ReduxActionTypes.INIT_FOLDER_MODULES,
            payload: { name: "", folderId: payload.folderId },
          });
        } else {
          yield put({
            type: ReduxActionTypes.INIT_MODULES,
            payload: { name: "" },
          });
        }
      }
    }
  } catch (error) {
    console.error("Error in addFoldersSaga:", error);
  }
}

export function* moveModuleSaga(action: {
  type: string;
  payload: {
    name: string;
    id: string;
    folderId: string;
    folderName: string;
    currentFolderId: string;
  };
}) {
  try {
    const { payload } = action;
    const request = {
      id: payload.id,
      name: payload.name,
      folderId: payload.folderId,
      folderName: payload.folderName,
    };
    const response: ApiResponse = yield call(ModulesApi.moveModule, request);
    const isValidResponse: boolean = yield call(validateResponse, response);
    if (isValidResponse) {
      if (payload.currentFolderId) {
        yield put({
          type: ReduxActionTypes.INIT_FOLDER_MODULES,
          payload: { name: "", folderId: payload.currentFolderId },
        });
      } else {
        yield put({
          type: ReduxActionTypes.INIT_MODULES,
          payload: { name: "" },
        });
      }
    }
  } catch (error) {
    console.error("Error in addFoldersSaga:", error);
  }
}

export function* copyModuleSaga(action: {
  type: string;
  payload: { name: string; id: string; folderId?: string };
}) {
  try {
    const { payload } = action;
    const request = {
      name: payload.name,
      id: payload.id,
    };
    const response: ApiResponse = yield call(ModulesApi.copyModule, request);
    const isValidResponse: boolean = yield call(validateResponse, response);
    if (isValidResponse) {
      if (isValidResponse) {
        if (payload.folderId) {
          yield put({
            type: ReduxActionTypes.INIT_FOLDER_MODULES,
            payload: { name: "", folderId: payload.folderId },
          });
        } else {
          yield put({
            type: ReduxActionTypes.INIT_MODULES,
            payload: { name: "" },
          });
        }
      }
    }
  } catch (error) {
    console.error("Error in addFoldersSaga:", error);
  }
}

export function* updateModuleSaga(action: {
  type: string;
  payload: { name: string; id: string; folderId: string };
}) {
  try {
    const { payload } = action;
    const request = {
      id: payload.id,
      name: payload.name,
    };
    const response: ApiResponse = yield call(
      ModulesApi.updateModuleName,
      request,
    );
    const isValidResponse: boolean = yield call(validateResponse, response);
    if (isValidResponse) {
      if (payload.folderId) {
        yield put({
          type: ReduxActionTypes.INIT_FOLDER_MODULES,
          payload: { name: "", folderId: payload.folderId },
        });
      } else {
        yield put({
          type: ReduxActionTypes.INIT_MODULES,
          payload: { name: "" },
        });
      }
    }
  } catch (error) {
    console.error("Error in addFoldersSaga:", error);
  }
}

export function* importApplicationSaga(
  action: ReduxAction<ImportModuleRequest>,
) {
  try {
    const response: ApiResponse = yield call(
      ModulesApi.importModule,
      action.payload,
    );
    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse) {
      if (action.payload.onSuccessCallback) {
        action.payload.onSuccessCallback();
      }
      if (action.payload.folderId) {
        yield put({
          type: ReduxActionTypes.INIT_FOLDER_MODULES,
          payload: { name: "", folderId: action.payload.folderId },
        });
      } else {
        yield put({
          type: ReduxActionTypes.INIT_MODULES,
          payload: { name: "" },
        });
      }
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes1.IMPORT_MODULE_ERROR,
      payload: {
        error,
      },
    });
  }
}

// 文件内
export function* getFolderModulesSaga(action: {
  type: string;
  payload: { name: string; folderId: string };
}) {
  try {
    const response: FetchUsersApplicationsWorkspacesResponse = yield call(
      ModulesApi.getFolderModules,
      action.payload.name,
      action.payload.folderId,
    );
    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.INIT_FOLDER_MODULES_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    // yield put({
    //   type: ReduxActionErrorTypes.INIT_MODULES_FOLDERS_ERROR,
    //   payload: {
    //     error,
    //   },
    // });
  }
}

// export function* addFolderModuleSaga(action: AddFoldersAction) {
//   try {
//     const { payload } = action;
//     const request = {
//       name: payload.name,
//     };
//     const response: ApiResponse = yield call(ModulesApi.addModule, request);
//     const isValidResponse: boolean = yield call(validateResponse, response);
//     if (isValidResponse) {
//       yield put({
//         type: ReduxActionTypes.INIT_MODULES,
//         payload: { name: "" },
//       });
//     }
//   } catch (error) {
//     console.error("Error in addFoldersSaga:", error);
//   }
// }

// export function* deleteFolderModuleSaga(action: {
//   type: string;
//   payload: { id: string };
// }) {
//   try {
//     const { payload } = action;
//     const request = {
//       id: payload.id,
//     };
//     const response: ApiResponse = yield call(ModulesApi.deleteModule, request);
//     const isValidResponse: boolean = yield call(validateResponse, response);
//     if (isValidResponse) {
//       yield put({
//         type: ReduxActionTypes.INIT_MODULES,
//         payload: { name: "" },
//       });
//     }
//   } catch (error) {
//     console.error("Error in addFoldersSaga:", error);
//   }
// }

// export function* moveFolderModuleSaga(action: {
//   type: string;
//   payload: { name: string; id: string; folderId: string; folderName: string };
// }) {
//   try {
//     const { payload } = action;
//     const request = {
//       id: payload.id,
//       name: payload.name,
//       folderId: payload.folderId,
//       folderName: payload.folderName,
//     };
//     const response: ApiResponse = yield call(ModulesApi.moveModule, request);
//     const isValidResponse: boolean = yield call(validateResponse, response);
//     if (isValidResponse) {
//       yield put({
//         type: ReduxActionTypes.INIT_MODULES,
//         payload: { name: "" },
//       });
//     }
//   } catch (error) {
//     console.error("Error in addFoldersSaga:", error);
//   }
// }

// export function* copyFolderModuleSaga(action: {
//   type: string;
//   payload: { name: string; id: string };
// }) {
//   try {
//     const { payload } = action;
//     const request = {
//       name: payload.name,
//       id: payload.id,
//     };
//     const response: ApiResponse = yield call(ModulesApi.copyModule, request);
//     const isValidResponse: boolean = yield call(validateResponse, response);
//     if (isValidResponse) {
//       yield put({
//         type: ReduxActionTypes.INIT_MODULES,
//         payload: { name: "" },
//       });
//     }
//   } catch (error) {
//     console.error("Error in addFoldersSaga:", error);
//   }
// }
