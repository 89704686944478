import Api from "api/Api";
import type { AxiosProgressEvent, AxiosPromise } from "axios";
import type { ApiResponse } from "api/ApiResponses";
import type { AppSnapshotList } from "constants/AppConstants";
import type { CreateSnapshotPayload, RestoreSnapshotPayload } from "ee/actions/appSnapshotActions";
import type { PaginationParam } from "constants/ApiEditorConstants/ApiEditorConstants";
import type { JSONValue } from "utils/AppUtils";

export interface AppSnapshotsResp extends ApiResponse {
  data: AppSnapshotList;
}

export interface AppSnapshotDslInfo {
  applicationsDsl: JSONValue;
  moduleDSL: Record<string, JSONValue>;
}

export interface AppSnapshotDslResp extends ApiResponse {
  data: AppSnapshotDslInfo;
}

class AppSnapshotApi extends Api {
  static createSnapshotURL = (appId: string) => `/v1/history-snapshots/${appId}`;
  static snapshotsURL = (appId: string, pageId: string) => `/v1/history-snapshots/list/${appId}/page/${pageId}`;
  static snapshotDslURL = (snapshotId: string) =>
    `/v1/history-snapshots/${snapshotId}`;
  static snapshotRestoreURL = (snapshotId: string) => `/v1/history-snapshots/${snapshotId}/rollback`;

  static createSnapshot(request: CreateSnapshotPayload): AxiosPromise<ApiResponse> {
    return Api.post(AppSnapshotApi.createSnapshotURL(request.applicationId), request);
  }

  static getSnapshots(appId: string, pageId: string, pagination: PaginationParam): AxiosPromise<AppSnapshotsResp> {
    return Api.get(AppSnapshotApi.snapshotsURL(appId, pageId), pagination);
  }

  static getSnapshotDsl(snapshotId: string, pageName: string): AxiosPromise<AppSnapshotDslResp> {
    return Api.get(AppSnapshotApi.snapshotDslURL(snapshotId), { pageName });
  }

  static restoreSnapshot(request: RestoreSnapshotPayload): AxiosPromise<AppSnapshotDslResp> {
    return Api.post(AppSnapshotApi.snapshotRestoreURL(request.snapshotId), request);
  }
}

export default AppSnapshotApi;
