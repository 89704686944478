import type { ReduxAction } from "ee/constants/ReduxActionConstants";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import type { AppSnapshotDslInfo } from "api/AppSnapshotAPI";
import type {
  AppSnapshotList,
  AppSnapshotContext,
} from "constants/AppConstants";
import type { PaginationParam } from "constants/ApiEditorConstants/ApiEditorConstants";
import type { JSONValue } from "utils/AppUtils";

export const setShowAppSnapshot = (show: boolean) => {
  return {
    type: ReduxActionTypes.SET_SHOW_APP_SNAPSHOT,
    payload: { show: show },
  };
};

export const setSelectSnapshotId = (snapshotId: string) => {
  return {
    type: ReduxActionTypes.SET_SELECT_SNAPSHOT_ID,
    payload: { snapshotId: snapshotId },
  };
};

export type CreateSnapshotPayload = {
  applicationId: string;
  dsl?: JSONValue;
  context: AppSnapshotContext;
  pageId: string;
};

export type RestoreSnapshotPayload = {
  snapshotId: string;
  context: AppSnapshotContext;
};

export const createSnapshotAction = (payload: any) => {
  return {
    type: ReduxActionTypes.CREATE_APP_SNAPSHOT,
    payload: payload,
  };
};

export type FetchSnapshotsPayload = {
  applicationId: string;
  pageId: string;
  onSuccess?: (snapshots: AppSnapshotList) => void;
} & PaginationParam;

export const fetchSnapshotsAction = (payload: FetchSnapshotsPayload) => {
  return {
    type: ReduxActionTypes.FETCH_APP_SNAPSHOTS,
    payload: payload,
  };
};

export type FetchSnapshotDslPayload = {
  snapshotId: string;
  onSuccess: (res: any) => void;
};

export const fetchSnapshotDslAction = (
  snapshotId: string,
  onSuccess: (res: any) => void,
): ReduxAction<FetchSnapshotDslPayload> => {
  return {
    type: ReduxActionTypes.FETCH_APP_SNAPSHOT_DSL,
    payload: {
      snapshotId,
      onSuccess,
    },
  };
};

export type RecoverSnapshotPayload = {
  snapshotId: string;
  snapshotCreateTime: number;
  onSuccess: (res: any) => void;
};

export const recoverSnapshotAction = (
  snapshotId: string,
  snapshotCreateTime: number,
  onSuccess: (res: any) => void,
): ReduxAction<RecoverSnapshotPayload> => {
  return {
    type: ReduxActionTypes.RECOVER_APP_SNAPSHOT,
    payload: {
      snapshotId,
      snapshotCreateTime,
      onSuccess,
    },
  };
};
