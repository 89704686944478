export * from "ce/sagas/tenantSagas";
import type { ReduxAction } from "ee/constants/ReduxActionConstants";
import {
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "ee/constants/ReduxActionConstants";
import type { UpdateCurrentTenantConfigRequest } from "ee/api/TenantApi";
import TenantApi from "ee/api/TenantApi";
import { updateTenantConfigSaga } from "ce/sagas/tenantSagas";
import { all, takeLatest, put, call } from "redux-saga/effects";
import { validateResponse } from "sagas/ErrorSagas";
import type { ApiResponse } from "api/ApiResponses";
import { Toaster, Variant } from "design-system-old";

export function* fetchCurrentTenantConfigSaga() {
  try {
    const response: ApiResponse = yield call(
      TenantApi.fetchCurrentTenantConfig,
    );
    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse && response.data) {
      yield put({
        type: ReduxActionTypes.FETCH_CURRENT_TENANT_CONFIG_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: ReduxActionErrorTypes.FETCH_CURRENT_TENANT_CONFIG_ERROR,
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.FETCH_CURRENT_TENANT_CONFIG_ERROR,
    });
  }
}

export function* updateCurrentTenantConfigSaga(
  action: ReduxAction<UpdateCurrentTenantConfigRequest>,
) {
  try {
    const response: ApiResponse = yield call(
      TenantApi.updateCurrentTenantConfig,
      action.payload,
    );
    const isValidResponse: boolean = yield validateResponse(response);
    if (isValidResponse && response.data) {
      yield put({
        type: ReduxActionTypes.UPDATE_CURRENT_TENANT_CONFIG_SUCCESS,
        payload: response.data,
      });
      Toaster.show({
        text: "自定义品牌保存成功",
        variant: Variant.success,
      });
    } else {
      yield put({
        type: ReduxActionErrorTypes.UPDATE_CURRENT_TENANT_CONFIG_ERROR,
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.UPDATE_CURRENT_TENANT_CONFIG_ERROR,
    });
  }
}

export default function* tenantSagas() {
  yield all([
    takeLatest(
      ReduxActionTypes.FETCH_CURRENT_TENANT_CONFIG,
      fetchCurrentTenantConfigSaga,
    ),
    takeLatest(
      ReduxActionTypes.UPDATE_CURRENT_TENANT_CONFIG,
      updateCurrentTenantConfigSaga,
    ),
    takeLatest(ReduxActionTypes.UPDATE_TENANT_CONFIG, updateTenantConfigSaga),
  ]);
}
