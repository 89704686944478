import { createReducer } from "utils/ReducerUtils";
import type { ReduxAction } from "ee/constants/ReduxActionConstants";
import {
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "ee/constants/ReduxActionConstants";

export const initialState: ModulesReduxState = {
  modules: [],
};

export const handlers = {
  [ReduxActionTypes.FETCH_MODULES_SUCCESS]: (
    state: any,
    action: ReduxAction<{ modules: any }>,
  ) => {
    return {
      ...state,
      modules: action.payload.modules,
    };
  },
};

const modulesReducer = createReducer(initialState, handlers);

export interface ModulesReduxState {}

export default modulesReducer;
