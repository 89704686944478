import {
  setLayoutConversionStateAction,
  updateSnapshotDetails,
} from "actions/autoLayoutActions";
import type { ApiResponse } from "api/ApiResponses";
import ApplicationApi from "ee/api/ApplicationApi";
import type { PageDefaultMeta } from "ee/api/ApplicationApi";
import type { ReduxAction } from "ee/constants/ReduxActionConstants";
import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "ee/constants/ReduxActionConstants";
import log from "loglevel";
import type { SnapShotDetails } from "reducers/uiReducers/layoutConversionReducer";
import { CONVERSION_STATES } from "reducers/uiReducers/layoutConversionReducer";
import {
  all,
  call,
  put,
  select,
  takeLatest,
  debounce,
} from "redux-saga/effects";
import {
  getCurrentApplicationId,
  getCurrentPageId,
  getSelectedSnapshotPage,
} from "selectors/editorSelectors";
import { getLogToSentryFromResponse } from "utils/helpers";
import { validateResponse } from "./ErrorSagas";
import { updateApplicationLayoutType } from "./AutoLayoutUpdateSagas";
import { LayoutSystemTypes } from "layoutSystems/types";
import AnalyticsUtil from "utils/AnalyticsUtil";
import { getLayoutSystemType } from "selectors/layoutSystemSelectors";
import type {
  CreateSnapshotPayload,
  FetchSnapshotsPayload,
  FetchSnapshotDslPayload,
  RecoverSnapshotPayload,
  RestoreSnapshotPayload,
} from "ee/actions/appSnapshotActions";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import AppSnapshotApi from "api/AppSnapshotAPI";
import type { AppSnapshotDslResp, AppSnapshotsResp } from "api/AppSnapshotAPI";
import type { UpdateApplicationResponse } from "ee/api/ApplicationApi";
import { createSnapshotAction } from "ee/actions/appSnapshotActions";
import type { OperationReduxState } from "reducers/uiReducers/operationReducer";
import { getOperations } from "sagas/selectors";
import _get from "lodash/get";
import _omit from "lodash/omit";
import _pick from "lodash/pick";
import type { AppSnapshot } from "constants/AppConstants";
import { saveLayout } from "actions/pageActions";
import OperationUtil from "utils/OperationUtil";
import moment from "moment";
//Saga to create application snapshot
export function* createSnapshotSaga() {
  let response: ApiResponse | undefined;
  try {
    const applicationId: string = yield select(getCurrentApplicationId);
    response = yield ApplicationApi.createApplicationSnapShot({
      applicationId,
    });

    const isValidResponse: boolean = yield validateResponse(
      response,
      false,
      getLogToSentryFromResponse(response),
    );

    if (isValidResponse) {
      return true;
    }
  } catch (error) {
    throw error;
  }
}

//Saga to fetch application snapshot
export function* fetchSnapshotSaga() {
  let response: ApiResponse<SnapShotDetails> | undefined;
  try {
    const applicationId: string = yield select(getCurrentApplicationId);
    response = yield ApplicationApi.getSnapShotDetails({
      applicationId,
    });

    const isValidResponse: boolean = yield validateResponse(
      response,
      false,
      getLogToSentryFromResponse(response),
    );

    if (isValidResponse) {
      const snapShotDetails = response?.data;

      return snapShotDetails;
    }
  } catch (error) {
    if (getLogToSentryFromResponse(response)) {
      log.error(error);
      throw error;
    }
  }
}

//Saga to restore application snapshot
function* restoreApplicationFromSnapshotSaga() {
  let response: ApiResponse<any> | undefined;
  try {
    const applicationId: string = yield select(getCurrentApplicationId);
    response = yield ApplicationApi.restoreApplicationFromSnapshot({
      applicationId,
    });

    const currentLayoutSystemType: LayoutSystemTypes =
      yield select(getLayoutSystemType);

    const isValidResponse: boolean = yield validateResponse(
      response,
      false,
      getLogToSentryFromResponse(response),
    );

    // update the pages list temporarily with incomplete data.
    if (response?.data?.pages) {
      yield put({
        type: ReduxActionTypes.FETCH_PAGE_LIST_SUCCESS,
        payload: {
          pages: response.data.pages.map((page: PageDefaultMeta) => ({
            pageId: page.id,
            isDefault: page.isDefault,
          })),
          applicationId,
        },
      });
    }

    //update layout system type from
    yield call(
      updateApplicationLayoutType,
      currentLayoutSystemType === LayoutSystemTypes.FIXED
        ? LayoutSystemTypes.AUTO
        : LayoutSystemTypes.FIXED,
    );

    if (isValidResponse) {
      //update conversion form state to success
      yield put(
        setLayoutConversionStateAction(CONVERSION_STATES.COMPLETED_SUCCESS),
      );
    }
  } catch (e: any) {
    let error: Error = e;
    if (error) {
      error.message = `Layout conversion error - while restoring snapshot: ${error.message}`;
    } else {
      error = new Error("Layout conversion error - while restoring snapshot");
    }

    log.error(error);
    //update conversion form state to error
    yield put(
      setLayoutConversionStateAction(CONVERSION_STATES.COMPLETED_ERROR),
    );
    throw error;
  }
}

//Saga to delete application snapshot
export function* deleteApplicationSnapshotSaga() {
  let response: ApiResponse | undefined;
  try {
    const applicationId: string = yield select(getCurrentApplicationId);
    response = yield ApplicationApi.deleteApplicationSnapShot({
      applicationId,
    });

    const isValidResponse: boolean = yield validateResponse(
      response,
      false,
      getLogToSentryFromResponse(response),
    );

    if (isValidResponse) {
      yield put(updateSnapshotDetails(undefined));
    }
  } catch (error) {
    log.error(error);
    throw error;
  }
}

//Saga to update snapshot details by fetching info from backend
function* updateSnapshotDetailsSaga() {
  try {
    const snapShotDetails: { updatedTime: Date } | undefined =
      yield call(fetchSnapshotSaga);
    yield put(
      updateSnapshotDetails(
        snapShotDetails && snapShotDetails.updatedTime
          ? { lastUpdatedTime: snapShotDetails.updatedTime?.toString() }
          : undefined,
      ),
    );
  } catch (error) {
    throw error;
  }
}

export function* createAppSnapshotSaga(
  action: ReduxAction<CreateSnapshotPayload>,
) {
  try {
    // create snapshot
    const operations: OperationReduxState = yield select(getOperations);
    const applicationId: string = yield select(getCurrentApplicationId);
    const currentPageId: string = yield select(getCurrentPageId);
    if (operations.logs.length) {
      AnalyticsUtil.logEvent("CREATE_PAGE_SNAPSHOT", {
        info: "保存页面快照",
        logs: operations.logs,
        appId: applicationId,
      });
      const request = {
        context: { operations: operations.logs },
        applicationId,
        pageId: currentPageId,
      };
      const response: AxiosResponse<ApiResponse> = yield call(
        AppSnapshotApi.createSnapshot,
        request,
      );
      if (validateResponse(response)) {
        yield put({
          type: ReduxActionTypes.CREATE_APP_SNAPSHOT_SUCCESS,
        });
        yield put({
          type: ReduxActionTypes.CLEAR_OPERATION_LOGS,
        });
      }
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.CREATE_APP_SNAPSHOT_ERROR,
    });
  }
}

export function* fetchAppSnapshotsSaga(
  action: ReduxAction<FetchSnapshotsPayload>,
) {
  try {
    const response: AxiosResponse<AppSnapshotsResp> = yield call(
      AppSnapshotApi.getSnapshots,
      action.payload.applicationId,
      action.payload.pageId,
      { page: action.payload.page, size: action.payload.size },
    );
    if (validateResponse(response)) {
      action.payload.onSuccess && action.payload.onSuccess(response?.data);
      yield put({
        type: ReduxActionTypes.FETCH_APP_SNAPSHOTS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.FETCH_APP_SNAPSHOTS_ERROR,
    });
  }
}

export function* fetchAppSnapshotDslSaga(
  action: ReduxAction<FetchSnapshotDslPayload>,
) {
  try {
    const res: AppSnapshot = yield select(getSelectedSnapshotPage);
    if (res.data) {
      action.payload.onSuccess(res.data);
      yield put({
        type: ReduxActionTypes.FETCH_APP_SNAPSHOT_DSL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.FETCH_APP_SNAPSHOT_DSL_ERROR,
    });
  }
}

export function* recoverAppSnapshotSaga(
  action: ReduxAction<RecoverSnapshotPayload>,
) {
  try {
    const { snapshotId, snapshotCreateTime } = action.payload;

    const selectdSN: AppSnapshot = yield select(getSelectedSnapshotPage);
    // record history record
    const request: RestoreSnapshotPayload = {
      snapshotId,
      context: {
        operations: [
          {
            compName: `快照(${moment(selectdSN.createTime).format("YYYY/MM/DD HH:mm:ss")}})`,
            operation: "recover",
            snapshotCreateTime: OperationUtil.getTimeStamp(),
          },
        ],
      },
    };
    const response: AxiosResponse<AppSnapshotDslResp> = yield call(
      AppSnapshotApi.restoreSnapshot,
      request,
    );
    if (validateResponse(response)) {
      action.payload.onSuccess(response.data);
      // update dsl of page
      yield put(saveLayout());
      yield window.location.reload();
    }
  } catch (error) {
    // messageInstance.error(trans("api.recoverFailed"));
  }
}

export function* handleSnapShotPreview(action: ReduxAction<any>) {
  try {
    const currentPage: any = _get(action.payload, ["page"], {});

    const currentPageInfo: any = _get(currentPage, ["unpublishedPage"], {});
    const pageInfo = _omit(currentPageInfo, ["layouts"]);
    const _pageWMDsl = {
      data: {
        ..._pick(currentPage, ["applicationId", "id"]),
        ...pageInfo,
        layouts: _get(currentPageInfo, "layouts"),
        defaultResources: {
          applicationId: _get(currentPage, ["applicationId", "id"]),
          pageId: _get(currentPage, ["id"], ""),
        },
      },
      errorDisplay: "",
      responseMeta: {
        status: 200,
        success: true,
      },
    };

    yield put({
      type: ReduxActionTypes.SETUP_PAGE_INIT,
      payload: {
        id: _get(action.payload, ["pageId"], ""),
        isFirstLoad: false,
        pageWithMigratedDsl: _pageWMDsl,
      },
    });
  } catch {}
}

export function* addNewSnapShot() {
  try {
    yield put(createSnapshotAction({}));
  } catch {}
}

export default function* snapshotSagas() {
  yield all([
    takeLatest(
      ReduxActionTypes.RESTORE_SNAPSHOT,
      restoreApplicationFromSnapshotSaga,
    ),
    takeLatest(
      [
        ReduxActionTypes.FETCH_LAYOUT_SNAPSHOT_DETAILS,
        ReduxActionTypes.START_CONVERSION_FLOW,
      ],
      updateSnapshotDetailsSaga,
    ),
    takeLatest(ReduxActionTypes.DELETE_SNAPSHOT, deleteApplicationSnapshotSaga),
    debounce(500, ReduxActionTypes.CREATE_APP_SNAPSHOT, createAppSnapshotSaga),
    takeLatest(ReduxActionTypes.FETCH_APP_SNAPSHOTS, fetchAppSnapshotsSaga),
    takeLatest(
      ReduxActionTypes.FETCH_APP_SNAPSHOT_DSL,
      fetchAppSnapshotDslSaga,
    ),
    takeLatest(ReduxActionTypes.RECOVER_APP_SNAPSHOT, recoverAppSnapshotSaga),
    takeLatest(
      ReduxActionTypes.FETCH_APP_SNAPSHOT_DSL_SUCCESS,
      handleSnapShotPreview,
    ),
  ]);
}
