import type { AppState } from "ee/reducers";
import { getAppsmithConfigs } from "ee/configs";
const { enableHelpDoc, enableTemplate } = getAppsmithConfigs();
export const getTenantPermissions = (state: AppState) => {
  return state.tenant?.userPermissions;
};

/**
 * selects the tenant config
 *
 * @param state
 * @returns
 */
export const getTenantConfig = (state: AppState) => {
  return state.tenant?.tenantConfiguration;
};

/**
 * selects the tenant brand colors
 *
 * @returns
 */
export const getBrandColors = () => {
  return {} as Record<string, string>;
};

export const isValidLicense = () => {
  return true;
};

export const isTenantLoading = (state: AppState) => {
  return state.tenant?.isLoading;
};

export const getGoogleMapsApiKey = (state: AppState): string | undefined =>
  state.tenant?.tenantConfiguration?.googleMapsKey as string | undefined;

export const getThirdPartyAuths = (state: AppState): string[] =>
  state.tenant?.tenantConfiguration?.thirdPartyAuths ?? [];

export const getIsFormLoginEnabled = (state: AppState): boolean =>
  state.tenant?.tenantConfiguration?.isFormLoginEnabled ?? true;

export const getInstanceId = (state: AppState): string =>
  state.tenant?.instanceId;
// export const getIsFormSignupEnable = (state: AppState) =>
//   state.settings.config?.APPSMITH_SIGNUP_DISABLED;

export const getIsFormSignupEnable = (state: AppState): boolean =>
  state.tenant?.tenantConfiguration?.isFormSignupEnabled;

// eslint-disable-next-line
export const shouldShowLicenseBanner = (state: AppState) => false;

export const getHideWatermark = (state: AppState): boolean =>
  state.tenant?.tenantConfiguration?.hideWatermark;
export const getIsTemplateEnabled = (state: AppState): boolean =>
  enableTemplate;
export const getIsFirstTimeUserGuideEnabled = (state: AppState): boolean =>
  state.tenant?.tenantConfiguration?.enableFirstTimeUserOnBoarding ?? false;
export const getIsHelpDocEnabled = (state: AppState): boolean => enableHelpDoc;
export const getInstanceName = (state: AppState): boolean =>
  state.tenant?.tenantConfiguration?.instanceName ?? false;
