import type { ExtraDef } from "utils/autocomplete/defCreatorUtils";
import { generateTypeDef } from "utils/autocomplete/defCreatorUtils";
import {
  ENTITY_TYPE,
  type AppsmithEntity,
} from "ee/entities/DataTree/types";
import _ from "lodash";
import { EVALUATION_PATH } from "utils/DynamicBindingUtils";
import type { Def } from "tern";
import type {
  ActionEntity,
  ActionEntityConfig,
  DataTreeEntityConfig,
  WidgetEntityConfig,
} from "ee/entities/DataTree/types";
import type { FieldEntityInformation } from "components/editorComponents/CodeEditor/EditorConfig";
import { AutocompleteDataType } from "utils/autocomplete/AutocompleteDataType";
import { eeAppsmithAutocompleteDefs } from "ee/utils/autocomplete/helpers";

const isVisible = {
  "!type": "bool",
  "!doc": "Boolean value indicating if the widget is in visible state",
};

export const entityDefinitions = {
  APPSMITH: (entity: AppsmithEntity, extraDefsToDefine: ExtraDef) => {
    const generatedTypeDef = generateTypeDef(
      _.omit(entity, "ENTITY_TYPE", EVALUATION_PATH),
      extraDefsToDefine,
    );
    if (
      typeof generatedTypeDef === "object" &&
      typeof generatedTypeDef.geolocation === "object"
    ) {
      return {
        ...generatedTypeDef,
        "!doc":
          "A global object that provides access to information and functionalities within an application",
        "!url": "https://docs.appsmith.com/reference/appsmith-framework",
        store: {
          ...(generatedTypeDef.store as Def),
          "!doc":
            "Object to access any app-level data or temporary state that is stored on the user's browser",
          "!url":
            "https://docs.appsmith.com/reference/appsmith-framework/context-object#store-object",
        },
        user: {
          ...(generatedTypeDef.user as Def),
          "!doc":
            "Object that contains the data of the currently authenticated user.",
          "!url":
            "https://docs.appsmith.com/reference/appsmith-framework/context-object#user-object",
        },
        URL: {
          ...(generatedTypeDef.URL as Def),
          "!doc": "Object containing all the attributes of the current URL",
          "!url":
            "https://docs.appsmith.com/reference/appsmith-framework/context-object#url-object",
        },
        theme: {
          ...(generatedTypeDef.theme as Def),
          "!doc":
            "Object containing the details of the theme properties applied to the application",
          "!url":
            "https://docs.appsmith.com/reference/appsmith-framework/context-object#theme-object",
        },
        mode: {
          "!type": generatedTypeDef.mode as Def,
          "!doc":
            "An enum that contains whether the app runs in view or edit mode. It takes the values VIEW or EDIT",
          "!url":
            "https://docs.appsmith.com/reference/appsmith-framework/context-object#mode-enum",
        },
        geolocation: {
          ...generatedTypeDef.geolocation,
          "!doc":
            "Object containing functions that allow you to retrieve the current user's location and the coordinates received from the user's device using the Geolocation API.",
          "!url":
            "https://docs.appsmith.com/reference/appsmith-framework/context-object#geolocation-object",
          getCurrentPosition: {
            "!type":
              "fn(onSuccess: fn() -> void, onError: fn() -> void, options: object) -> +Promise|void",
            "!url":
              "https://docs.appsmith.com/reference/appsmith-framework/context-object#geolocationgetcurrentposition",
          },
          watchPosition: {
            "!type": "fn(options: object) -> void",
            "!url":
              "https://docs.appsmith.com/reference/appsmith-framework/context-object#geolocationwatchposition",
          },
          clearWatch: {
            "!type": "fn() -> +Promise|void",
            "!url":
              "https://docs.appsmith.com/reference/appsmith-framework/context-object#geolocationclearwatch",
          },
        },
        echartInstance: {
          callFunc:
            "fn(widgetName: string, funcName: string, options?: any) -> +Promise[:t=[!0.<i>.:t]]",
        },
        windowMessageListener: {
          "!doc":
            "Enable an pageplug application to capture and react to the messages incoming from a parent application.",
          "!type": "fn(domain: string, callback: fn) -> +Promise",
        },
        unlistenWindowMessage: {
          "!doc":
            "allows you to disable an Pageplug app from reacting to messages from the parent website.",
          "!type": "fn(domain: string) -> +Promise",
        },
        ...eeAppsmithAutocompleteDefs(generatedTypeDef),
      };
    }
    return generatedTypeDef;
  },
  ACTION: (entity: ActionEntity, extraDefsToDefine: ExtraDef) => {
    const dataDef = generateTypeDef(entity.data, extraDefsToDefine);
    let responseMetaDef = generateTypeDef(
      entity.responseMeta,
      extraDefsToDefine,
    );

    if (_.isString(responseMetaDef)) {
      responseMetaDef = {
        "!type": responseMetaDef,
      };
    }

    let dataCustomDef: Def = {
      "!doc":
        "A read-only property that contains the response body from the last successful execution of this query.",
      "!url":
        "https://docs.appsmith.com/reference/appsmith-framework/query-object#data-array",
    };

    if (_.isString(dataDef)) {
      dataCustomDef["!type"] = dataDef;
    } else {
      dataCustomDef = { ...dataCustomDef, ...dataDef };
    }
    return {
      "!doc":
        "Object that contains the properties required to run queries and access the query data.",
      "!url":
        "https://docs.appsmith.com/reference/appsmith-framework/query-object",
      isLoading: {
        "!type": "bool",
        "!doc":
          "Boolean that indicates whether the query is currently being executed.",
      },
      data: dataCustomDef,
      responseMeta: {
        "!doc":
          "Object that contains details about the response, such as the status code, headers, and other relevant information related to the query's execution and the server's response.",
        "!url":
          "https://docs.appsmith.com/reference/appsmith-framework/query-object#responsemeta-object",
        ...responseMetaDef,
      },
      run: {
        "!type": "fn(params?: {}) -> +Promise",
        "!url":
          "https://docs.appsmith.com/reference/appsmith-framework/query-object#queryrun",
        "!doc": "Executes the query with the given parameters.",
      },
      clear: {
        "!type": "fn() -> +Promise",
        "!url":
          "https://docs.appsmith.com/reference/appsmith-framework/query-object#queryclear",
        "!doc": "Clears the query data.",
      },
    };
  },
  ECHART_WIDGET: {
    "!doc":
      "EChart widget is used to view the graphical representation of your data. Echart is the go-to widget for your data visualisation needs.",
    "!url": "https://docs.appsmith.com/widget-reference/chart",
    isVisible: true,
    chartData: {
      seriesName: "string",
      // data: "[$__chartDataPoint__$]",
      data: "any",
    },
    xAxisName: "string",
    yAxisName: "string",
    selectedDataItem: "any",
  },
  CUSTOMCOMPONENT_WIDGET: {
    "!doc": "custom widget.",
    "!url": "https://docs.appsmith.com/widget-reference/chart",
    isVisible: true,
    model: "any",
    code: "any",
  },
  FORMILY_WIDGET: {
    "!doc": "复杂表单解决方案",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    isVisible: isVisible,
    formData: "any",
  },
  TARO_PICKER_WIDGET: {
    "!doc": "Taro 选择器",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    isVisible: isVisible,
  },
  TARO_SWIPER_WIDGET: {
    "!doc": "轮播",
    "!url": "https://docs.appsmith.com/widget-reference/input",
  },
  TARO_GRID_WIDGET: {
    "!doc": "网格内容",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    currentItem: "any",
  },
  TARO_TEXT_WIDGET: {
    "!doc": "文本",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    text: "string",
  },
  TARO_LIST_WIDGET: {
    "!doc": "列表",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    currentItem: "any",
    data: "[any]",
  },
  TARO_POPUP_WIDGET: {
    "!doc": "底部弹窗",
    "!url": "https://docs.appsmith.com/widget-reference/input",
  },
  TARO_IMAGE_WIDGET: {
    "!doc": "图片",
    "!url": "https://docs.appsmith.com/widget-reference/input",
  },
  TARO_BUTTON_WIDGET: {
    "!doc": "按钮",
    "!url": "https://docs.appsmith.com/widget-reference/input",
  },
  TARO_CELL_WIDGET: {
    "!doc": "单元格",
    "!url": "https://docs.appsmith.com/widget-reference/input",
  },
  TARO_HTML_WIDGET: {
    "!doc": "富文本",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    content: "string",
  },
  TARO_SKU_WIDGET: {
    "!doc": "商品规格",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    submitData: "any",
  },
  TARO_KV_WIDGET: {
    "!doc": "键值对",
    "!url": "https://docs.appsmith.com/widget-reference/input",
  },
  TARO_TABS_WIDGET: {
    "!doc": "标签页",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    selectedTab: "any",
  },
  TARO_BOTTOM_BAR_WIDGET: {
    "!doc": "底部面板",
    "!url": "https://docs.appsmith.com/widget-reference/input",
  },
  TARO_ACTION_BAR_WIDGET: {
    "!doc": "动作栏",
    "!url": "https://docs.appsmith.com/widget-reference/input",
  },
  TARO_SEARCH_WIDGET: {
    "!doc": "搜索框",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    text: "string",
  },
  TARO_LOADING_WIDGET: {
    "!doc": "加载遮罩",
    "!url": "https://docs.appsmith.com/widget-reference/input",
  },
  TARO_CHECKBOX_WIDGET: {
    "!doc": "复选框",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    isVisible,
    isChecked: "bool",
    isDisabled: "bool",
  },
  TARO_FORM_WIDGET: {
    "!doc": "表单",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    formData: "any",
  },
  MAPBACKDROP_WIDGET: {
    "!doc":
      "EChart widget is used to view the graphical representation of your data. Chart is the go-to widget for your data visualisation needs.",
    "!url": "https://docs.appsmith.com/widget-reference/chart",
    isVisible: true,
    chartData: {
      seriesName: "string",
      // data: "[$__chartDataPoint__$]",
      data: "any",
      custom_name: "string",
    },
    xAxisName: "string",
    yAxisName: "string",
    selectedDataItem: "any",
  },

  MULTI_SELECT_TREE_WIDGET: {
    "!doc":
      "Multi TreeSelect is used to capture user inputs from a specified list of permitted inputs/Nested Inputs. A TreeSelect can capture a single choice as well as multiple choices",
    "!url": "https://docs.appsmith.com/widget-reference/treeselect",
    isVisible: isVisible,
    selectedOptionValues: {
      "!type": "[string]",
      "!doc": "The array of values selected in a treeselect dropdown",
      "!url": "https://docs.appsmith.com/widget-reference/treeselect",
    },
    selectedOptionLabels: {
      "!type": "[string]",
      "!doc": "The array of selected option labels in a treeselect dropdown",
      "!url": "https://docs.appsmith.com/widget-reference/treeselect",
    },
    isDisabled: "bool",
    isValid: "bool",
    options: "[$__dropdownOption__$]",
  },
  ICON_BUTTON_WIDGET: {
    "!doc":
      "Icon button widget is just an icon, along with all other button properties.",
    "!url": "https://docs.appsmith.com/widget-reference/icon-button",
    isVisible: isVisible,
  },
  CHECKBOX_GROUP_WIDGET: {
    "!doc":
      "Checkbox group widget allows users to easily configure multiple checkboxes together.",
    "!url": "https://docs.appsmith.com/widget-reference/checkbox-group",
    isVisible: isVisible,
    isDisabled: "bool",
    isValid: "bool",
    options: "[$__dropdownOption__$]",
    selectedValues: "[string]",
  },
  STATBOX_WIDGET: {
    "!doc": "Show and highlight stats from your data sources",
    "!url": "https://docs.appsmith.com/widget-reference/stat-box",
    isVisible: isVisible,
  },
  AUDIO_RECORDER_WIDGET: {
    "!doc":
      "Audio recorder widget allows users to record using their microphone, listen to the playback, and export the data to a data source.",
    "!url": "https://docs.appsmith.com/widget-reference/recorder",
    isVisible: isVisible,
    blobURL: "string",
    dataURL: "string",
    rawBinary: "string",
  },
  PROGRESSBAR_WIDGET: {
    "!doc": "Progress bar is a simple UI widget used to show progress",
    "!url": "https://docs.appsmith.com/widget-reference/progressbar",
    isVisible: isVisible,
    progress: "number",
  },
  SWITCH_GROUP_WIDGET: {
    "!doc":
      "Switch group widget allows users to create many switch components which can easily by used in a form",
    "!url": "https://docs.appsmith.com/widget-reference/switch-group",
    selectedValues: "[string]",
  },
  CAMERA_WIDGET: {
    "!doc":
      "Camera widget allows users to take a picture or record videos through their system camera using browser permissions.",
    "!url": "https://docs.appsmith.com/widget-reference/camera",
    imageBlobURL: "string",
    imageDataURL: "string",
    imageRawBinary: "string",
    videoBlobURL: "string",
    videoDataURL: "string",
    videoRawBinary: "string",
  },
  MAP_CHART_WIDGET: {
    "!doc":
      "Map Chart widget shows the graphical representation of your data on the map.",
    "!url": "https://docs.appsmith.com/widget-reference/map-chart",
    isVisible: isVisible,
    selectedDataPoint: {
      id: "string",
      label: "string",
      originalId: "string",
      shortLabel: "string",
      value: "number",
    },
  },
  INPUT_WIDGET_V2: {
    "!doc":
      "An input text field is used to capture a users textual input such as their names, numbers, emails etc. Inputs are used in forms and can have custom validations.",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    text: {
      "!type": "string",
      "!doc": "The text value of the input",
      "!url": "https://docs.appsmith.com/widget-reference/input",
    },
    inputText: {
      "!type": "string",
      "!doc": "The unformatted text value of the input",
      "!url": "https://docs.appsmith.com/widget-reference/input",
    },
    isValid: "bool",
    isVisible: isVisible,
    isDisabled: "bool",
  },
  CURRENCY_INPUT_WIDGET: {
    "!doc":
      "An input text field is used to capture a currency value. Inputs are used in forms and can have custom validations.",
    "!url": "https://docs.appsmith.com/widget-reference/currency-input",
    text: {
      "!type": "string",
      "!doc": "The formatted text value of the input",
      "!url": "https://docs.appsmith.com/widget-reference/currency-input",
    },
    value: {
      "!type": "number",
      "!doc": "The value of the input",
      "!url": "https://docs.appsmith.com/widget-reference/currency-input",
    },
    isValid: "bool",
    isVisible: isVisible,
    isDisabled: "bool",
    countryCode: {
      "!type": "string",
      "!doc": "Selected country code for Currency",
    },
    currencyCode: {
      "!type": "string",
      "!doc": "Selected Currency code",
    },
  },
  PHONE_INPUT_WIDGET: {
    "!doc":
      "An input text field is used to capture a phone number. Inputs are used in forms and can have custom validations.",
    "!url": "https://docs.appsmith.com/widget-reference/phone-input",
    text: {
      "!type": "string",
      "!doc": "The text value of the input",
      "!url": "https://docs.appsmith.com/widget-reference/phone-input",
    },
    value: {
      "!type": "string",
      "!doc": "The unformatted text value of the input",
      "!url": "https://docs.appsmith.com/widget-reference/phone-input",
    },
    isValid: "bool",
    isVisible: isVisible,
    isDisabled: "bool",
    countryCode: {
      "!type": "string",
      "!doc": "Selected country code for Phone Number",
    },
    dialCode: {
      "!type": "string",
      "!doc": "Selected dialing code for Phone Number",
    },
  },
  CIRCULAR_PROGRESS_WIDGET: {
    "!doc": "Circular Progress is a simple UI widget used to show progress",
    "!url": "https://docs.appsmith.com/widget-reference/circular-progress",
    isVisible: isVisible,
    progress: "number",
  },
  JSON_FORM_WIDGET: (widget: any) => ({
    "!doc":
      "JSON Form widget can be used to auto-generate forms by providing a JSON source data.",
    // TODO: Update the url
    "!url": "https://docs.appsmith.com/widget-reference",
    formData: generateTypeDef(widget.formData),
    sourceData: generateTypeDef(widget.sourceData),
    fieldState: generateTypeDef(widget.fieldState),
    isValid: "bool",
  }),
  PROGRESS_WIDGET: {
    "!doc":
      "Progress indicators commonly known as spinners, express an unspecified wait time or display the length of a process.",
    "!url": "https://docs.appsmith.com/widget-reference/progress",
    isVisible: isVisible,
    progress: "number",
  },
  DOCUMENT_VIEWER_WIDGET: {
    "!doc": "Document viewer widget is used to show documents on a page",
    "!url": "https://docs.appsmith.com/reference/widgets/document-viewer",
    isVisible: isVisible,
    docUrl: "string",
  },
  NUMBER_SLIDER_WIDGET: {
    "!doc":
      "Number slider widget is used to capture user feedback from a range of values",
    "!url": "https://docs.appsmith.com/widget-reference/circular-progress",
    isVisible: isVisible,
    value: "number",
  },
  CATEGORY_SLIDER_WIDGET: {
    "!doc":
      "Category slider widget is used to capture user feedback from a range of categories",
    "!url": "https://docs.appsmith.com/widget-reference/circular-progress",
    isVisible: isVisible,
    value: "string",
  },
  RANGE_SLIDER_WIDGET: {
    "!doc":
      "Range slider widget is used to capture user feedback from a range of values",
    "!url": "https://docs.appsmith.com/widget-reference/circular-progress",
    isVisible: isVisible,
    start: "number",
    end: "number",
  },
  CODE_SCANNER_WIDGET: {
    "!doc": "Scan a Code",
    "!url": "https://docs.appsmith.com/reference/widgets/code-scanner",
    isVisible: isVisible,
    isDisabled: "bool",
    value: "string",
  },
  ANIMATION_WIDGET: {
    "!doc":
      "Category slider widget is used to capture user feedback from a range of categories",
    "!url": "https://docs.appsmith.com/widget-reference/circular-progress",
    isVisible: isVisible,
    value: "string",
  },
};

/*
  $__name__$ is just to reduce occurrences of global def showing up in auto completion for user as `$` is less commonly used as entityName/

  GLOBAL_DEFS are maintained to support definition for array of objects which currently aren't supported by our generateTypeDef.
*/
export const GLOBAL_DEFS = {
  $__dropdownOption__$: {
    label: "string",
    value: "string",
  },
  $__dropdrowOptionWithChildren__$: {
    label: "string",
    value: "string",
    children: "[$__dropdrowOptionWithChildren__$]",
  },
  $__chartDataPoint__$: {
    x: "string",
    y: "string",
  },
  $__file__$: {
    data: "string",
    dataFormat: "string",
    name: "text",
    type: "file",
  },
  $__mapMarker__$: {
    lat: "number",
    long: "number",
    title: "string",
    description: "string",
  },
};

export const GLOBAL_FUNCTIONS = {
  "!name": "DATA_TREE.APPSMITH.FUNCTIONS",
  navigateTo: {
    "!url":
      "https://docs.appsmith.com/reference/appsmith-framework/widget-actions/navigate-to",
    "!doc":
      "Enables navigation between the internal pages of the App or to an external URL.",
    "!type":
      "fn(pageNameOrUrl: string, params: {}, target?: string) -> +Promise",
  },
  showAlert: {
    "!url":
      "https://docs.appsmith.com/reference/appsmith-framework/widget-actions/show-alert",
    "!doc":
      "Displays a temporary toast-style alert message to the user for precisely 5 seconds. The duration of the alert message can't be modified.",
    "!type": "fn(message: string, style: string) -> +Promise",
  },
  showModal: {
    "!url":
      "https://docs.appsmith.com/reference/appsmith-framework/widget-actions/show-modal",
    "!doc":
      "Opens an existing Modal widget and bring it into focus on the page",
    "!type": "fn(modalName: string) -> +Promise",
  },
  logout: {
    "!doc": "logout the user",
    "!type": "fn() -> +Promise[:t=[!0.<i>.:t]]",
  },
  closeModal: {
    "!url":
      "https://docs.appsmith.com/reference/appsmith-framework/widget-actions/close-modal",
    "!doc": "Close a modal",
    "!type": "fn(modalName: string) -> +Promise",
  },
  storeValue: {
    "!url":
      "https://docs.appsmith.com/reference/appsmith-framework/widget-actions/store-value",
    "!doc":
      "Stores the data in the browser's local storage as key-value pairs that represent storage objects and can be later accessed anywhere in the application via <code>appsmith.store</code>.",
    "!type": "fn(key: string, value: any, persist?: bool) -> +Promise",
  },
  removeValue: {
    "!url":
      "https://docs.appsmith.com/reference/appsmith-framework/widget-actions/remove-value",
    "!doc": "Remove key value data locally",
    "!type": "fn(key: string) -> +Promise",
  },
  clearStore: {
    "!url":
      "https://docs.appsmith.com/reference/appsmith-framework/widget-actions/clear-store",
    "!doc": "Clear all key value data locally",
    "!type": "fn() -> +Promise",
  },
  download: {
    "!url":
      "https://docs.appsmith.com/reference/appsmith-framework/widget-actions/download",
    "!doc":
      "Download any data as a file, leveraging the capabilities of the downloadjs library.",
    "!type":
      "fn(data: string|+Blob, fileName: string, fileType?: string) -> +Promise",
  },
  copyToClipboard: {
    "!url":
      "https://docs.appsmith.com/reference/appsmith-framework/widget-actions/copy-to-clipboard",
    "!doc": "Copies the given text to clipboard",
    "!type": "fn(data: string, options: object) -> +Promise",
  },
  resetWidget: {
    "!url":
      "https://docs.appsmith.com/reference/appsmith-framework/widget-actions/reset-widget",
    "!doc":
      "Resets a widget to its default state. All user input changes are reverted and its properties' default values are applied.",
    "!type": "fn(widgetName: string, resetChildren: bool) -> +Promise",
  },
  setInterval: {
    "!url":
      "https://docs.appsmith.com/reference/appsmith-framework/widget-actions/intervals-time-events",
    "!doc": "Executes a function at a given interval",
    "!type":
      "fn(callback: fn() -> void, interval: number, id?: string) -> number",
  },
  clearInterval: {
    "!url":
      "https://docs.appsmith.com/reference/appsmith-framework/widget-actions/clear-interval",
    "!doc": "Stop executing a setInterval with id",
    "!type": "fn(id: string) -> void",
  },
  postWindowMessage: {
    "!url":
      "https://docs.appsmith.com/reference/appsmith-framework/widget-actions/post-message",
    "!doc":
      "Establish cross-origin communication between Window objects/page and iframes",
    "!type": "fn(message: unknown, source: string, targetOrigin: string)",
  },
  print: {
    "!doc": "print the page",
    "!type": "fn() -> +Promise[:t=[!0.<i>.:t]]",
  },
};

export const ternDocsInfo: Record<string, any> = {
  showAlert: {
    exampleArgs: [
      "'This is a success message', 'success'",
      "'This is an error message', 'error'",
    ],
  },
  showModal: {
    exampleArgs: ["'Modal1'"],
  },
  closeModal: {
    exampleArgs: ["'Modal1'"],
  },
  navigateTo: {
    exampleArgs: [
      "'Page1', { id: 1 }",
      "'https://appsmith.com', {}, 'NEW_WINDOW'",
    ],
  },
  copyToClipboard: {
    exampleArgs: ["'Hello'"],
  },
  download: {
    exampleArgs: [
      "'Hello World', 'hello.txt', 'text/plain'",
      "FilePicker1.files[0].data, 'data.json'",
    ],
  },
  storeValue: {
    exampleArgs: ["'key', 'value'"],
  },
  removeValue: {
    exampleArgs: ["'key'"],
  },
  clearStore: {
    exampleArgs: [""],
  },
  resetWidget: {
    exampleArgs: ["'Table1', false"],
  },
  setInterval: {
    exampleArgs: ["() => showAlert('Hello'), 1000, 'id'"],
  },
  clearInterval: {
    exampleArgs: ["'id'"],
  },
  postWindowMessage: {
    exampleArgs: ["message, 'Iframe1', '*'"],
  },
};

export type EntityDefinitionsOptions = keyof typeof entityDefinitions;

export const getEachEntityInformation = {
  [ENTITY_TYPE.ACTION]: (
    entity: DataTreeEntityConfig,
    entityInformation: FieldEntityInformation,
  ): FieldEntityInformation => {
    const actionEntity = entity as ActionEntityConfig;
    entityInformation.entityId = actionEntity.actionId;
    return entityInformation;
  },
  [ENTITY_TYPE.WIDGET]: (
    entity: DataTreeEntityConfig,
    entityInformation: FieldEntityInformation,
    propertyPath: string,
  ): FieldEntityInformation => {
    const widgetEntity = entity as WidgetEntityConfig;
    const isTriggerPath = widgetEntity.triggerPaths[propertyPath];
    entityInformation.entityId = widgetEntity.widgetId;
    if (isTriggerPath)
      entityInformation.expectedType = AutocompleteDataType.FUNCTION;
    entityInformation.isTriggerPath = isTriggerPath;
    entityInformation.widgetType = widgetEntity.type;
    return entityInformation;
  },
  [ENTITY_TYPE.JSACTION]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    entity: DataTreeEntityConfig,
    entityInformation: FieldEntityInformation,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    propertyPath: string,
  ): FieldEntityInformation => {
    entityInformation.isTriggerPath = true;
    return entityInformation;
  },
};
