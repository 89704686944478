export * from "ce/reducers/settingsReducer";
import {
  handlers as handlersCE,
  initialState as initialStateCE,
  SettingsReduxState as SettingsReduxStateCE,
} from "ce/reducers/settingsReducer";
import {
  ReduxAction,
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "ee/constants/ReduxActionConstants";
import { createReducer } from "utils/ReducerUtils";
import { UserType } from "ee/api/UserApi";
import { RoleData } from "ee/api/RoleApi";
import { GroupData, GroupDetailData } from "ee/api/UserGroupApi";

export interface SettingsReduxState extends SettingsReduxStateCE {
  user: {
    loadingStates: {
      isFetchingUsers: boolean;
      isFetchingUserDetail: boolean;
    };
    list: UserType[];
    detail: UserType;
  };
  role: {
    loadingStates: {
      isFetchingRoles: boolean;
      isFetchingRoleDetail: boolean;
      isFetchingRolesForAssign: boolean;
      isAssociatingRoles: boolean;
      isUpdatingRole: boolean;
      isDeletingRole: boolean;
      isCreatingRole: boolean;
      isFetchingRoleConfig: boolean;
      isUpdatingRoleConfig: boolean;
    };
    listForAssign: RoleData[];
    list: RoleData[];
    detail: RoleData;
    roleConfig: any;
    associateUpdateKey: number;
  };
  group: {
    loadingStates: {
      isFetchingGroups: boolean;
      isFetchingGroupDetail: boolean;
      isUpdatingGroup: boolean;
      isDeletingGroup: boolean;
      isCreatingGroup: boolean;
      isSavingUserTeams: boolean;
    };
    list: GroupData[];
    detail: GroupDetailData;
    userTeamsUpdateKey: number;
  };
}

export const initialState: SettingsReduxState = {
  ...initialStateCE,
  user: {
    loadingStates: {
      isFetchingUsers: false,
      isFetchingUserDetail: false,
    },
    list: [],
    detail: {
      id: "",
      name: "",
      username: "",
      roles: [],
      groups: [],
    },
  },
  role: {
    loadingStates: {
      isFetchingRoles: false,
      isFetchingRoleDetail: false,
      isFetchingRolesForAssign: false,
      isAssociatingRoles: false,
      isUpdatingRole: false,
      isDeletingRole: false,
      isCreatingRole: false,
      isFetchingRoleConfig: false,
      isUpdatingRoleConfig: false,
    },
    listForAssign: [],
    list: [],
    detail: {
      id: "",
      name: "",
      description: "",
      autoCreated: false,
    },
    roleConfig: {
      id: "",
      name: "",
      description: "",
      autoCreated: false,
      tabs: [],
    },
    associateUpdateKey: 0,
  },
  group: {
    loadingStates: {
      isFetchingGroups: false,
      isFetchingGroupDetail: false,
      isUpdatingGroup: false,
      isDeletingGroup: false,
      isCreatingGroup: false,
      isSavingUserTeams: false,
    },
    list: [],
    detail: {
      id: "",
      name: "",
      description: "",
      users: [],
      roles: [],
    },
    userTeamsUpdateKey: 0,
  },
};

export const handlers = {
  ...handlersCE,
  // users
  [ReduxActionTypes.FETCH_USERS_INIT]: (state: SettingsReduxState) => ({
    ...state,
    user: {
      ...state.user,
      loadingStates: {
        ...state.user.loadingStates,
        isFetchingUsers: true,
      },
    },
  }),
  [ReduxActionTypes.FETCH_USERS_SUCCESS]: (
    state: SettingsReduxState,
    action: ReduxAction<UserType[]>,
  ) => ({
    ...state,
    user: {
      ...state.user,
      loadingStates: {
        ...state.user.loadingStates,
        isFetchingUsers: false,
      },
      list: action.payload,
    },
  }),
  [ReduxActionErrorTypes.FETCH_USERS_ERROR]: (state: SettingsReduxState) => ({
    ...state,
    user: {
      ...state.user,
      loadingStates: {
        ...state.user.loadingStates,
        isFetchingUsers: false,
      },
    },
  }),
  [ReduxActionTypes.FETCH_USER_DETAIL_INIT]: (state: SettingsReduxState) => ({
    ...state,
    user: {
      ...state.user,
      loadingStates: {
        ...state.user.loadingStates,
        isFetchingUserDetail: true,
      },
    },
  }),
  [ReduxActionTypes.FETCH_USER_DETAIL_SUCCESS]: (
    state: SettingsReduxState,
    action: ReduxAction<UserType>,
  ) => ({
    ...state,
    user: {
      ...state.user,
      loadingStates: {
        ...state.user.loadingStates,
        isFetchingUserDetail: false,
      },
      detail: action.payload,
    },
  }),
  [ReduxActionErrorTypes.FETCH_USER_DETAIL_ERROR]: (
    state: SettingsReduxState,
  ) => ({
    ...state,
    user: {
      ...state.user,
      loadingStates: {
        ...state.user.loadingStates,
        isFetchingUserDetail: false,
      },
    },
  }),
  // roles
  [ReduxActionTypes.FETCH_ROLES_FOR_USER_ASSIGN_INIT]: (
    state: SettingsReduxState,
  ) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isFetchingRolesForAssign: true,
      },
    },
  }),
  [ReduxActionTypes.FETCH_ROLES_FOR_USER_ASSIGN_SUCCESS]: (
    state: SettingsReduxState,
    action: ReduxAction<RoleData[]>,
  ) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isFetchingRolesForAssign: false,
      },
      listForAssign: action.payload,
    },
  }),
  [ReduxActionErrorTypes.FETCH_ROLES_FOR_USER_ASSIGN_ERROR]: (
    state: SettingsReduxState,
  ) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isFetchingRolesForAssign: false,
      },
    },
  }),
  [ReduxActionTypes.FETCH_ROLES_INIT]: (state: SettingsReduxState) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isFetchingRoles: true,
      },
    },
  }),
  [ReduxActionTypes.FETCH_ROLES_SUCCESS]: (
    state: SettingsReduxState,
    action: ReduxAction<RoleData[]>,
  ) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isFetchingRoles: false,
      },
      list: action.payload,
    },
  }),
  [ReduxActionErrorTypes.FETCH_ROLES_ERROR]: (state: SettingsReduxState) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isFetchingRoles: false,
      },
    },
  }),
  [ReduxActionTypes.FETCH_ROLE_SUCCESS]: (
    state: SettingsReduxState,
    action: ReduxAction<RoleData>,
  ) => ({
    ...state,
    role: {
      ...state.role,
      detail: action.payload,
    },
  }),
  [ReduxActionTypes.CREATE_ROLE_INIT]: (state: SettingsReduxState) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isCreatingRole: true,
      },
    },
  }),
  [ReduxActionTypes.CREATE_ROLE_SUCCESS]: (state: SettingsReduxState) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isCreatingRole: false,
      },
    },
  }),
  [ReduxActionErrorTypes.CREATE_ROLE_ERROR]: (state: SettingsReduxState) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isCreatingRole: false,
      },
    },
  }),
  [ReduxActionTypes.DELETE_ROLE_INIT]: (state: SettingsReduxState) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isDeletingRole: true,
      },
    },
  }),
  [ReduxActionTypes.DELETE_ROLE_SUCCESS]: (state: SettingsReduxState) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isDeletingRole: false,
      },
    },
  }),
  [ReduxActionErrorTypes.DELETE_ROLE_ERROR]: (state: SettingsReduxState) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isDeletingRole: false,
      },
    },
  }),
  [ReduxActionTypes.UPDATE_ROLE_INIT]: (state: SettingsReduxState) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isUpdatingRole: true,
      },
    },
  }),
  [ReduxActionTypes.UPDATE_ROLE_SUCCESS]: (
    state: SettingsReduxState,
    action: ReduxAction<RoleData>,
  ) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isUpdatingRole: false,
      },
      detail: {
        ...state.role.detail,
        name: action.payload.name,
        description: action.payload.description,
      },
    },
  }),
  [ReduxActionErrorTypes.UPDATE_ROLE_ERROR]: (state: SettingsReduxState) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isUpdatingRole: false,
      },
    },
  }),
  [ReduxActionTypes.FETCH_ROLE_CONFIG_INIT]: (state: SettingsReduxState) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isFetchingRoleConfig: true,
      },
      roleConfig: {
        id: "",
        name: "",
        description: "",
        autoCreated: false,
        tabs: [],
      },
    },
  }),
  [ReduxActionTypes.FETCH_ROLE_CONFIG_SUCCESS]: (
    state: SettingsReduxState,
    action: ReduxAction<any>,
  ) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isFetchingRoleConfig: false,
      },
      roleConfig: action.payload,
    },
  }),
  [ReduxActionErrorTypes.FETCH_ROLE_CONFIG_ERROR]: (
    state: SettingsReduxState,
  ) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isFetchingRoleConfig: false,
      },
    },
  }),
  [ReduxActionTypes.UPDATE_ROLE_CONFIG_INIT]: (state: SettingsReduxState) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isUpdatingRoleConfig: true,
      },
    },
  }),
  [ReduxActionTypes.UPDATE_ROLE_CONFIG_SUCCESS]: (
    state: SettingsReduxState,
    action: ReduxAction<any>,
  ) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isUpdatingRoleConfig: false,
      },
      roleConfig: action.payload,
      associateUpdateKey: state.role.associateUpdateKey + 1,
    },
  }),
  [ReduxActionErrorTypes.UPDATE_ROLE_CONFIG_ERROR]: (
    state: SettingsReduxState,
  ) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isUpdatingRoleConfig: false,
      },
    },
  }),
  // user groups
  [ReduxActionTypes.FETCH_USER_GROUPS_INIT]: (state: SettingsReduxState) => ({
    ...state,
    group: {
      ...state.group,
      loadingStates: {
        ...state.group.loadingStates,
        isFetchingGroups: true,
      },
    },
  }),
  [ReduxActionTypes.FETCH_USER_GROUPS_SUCCESS]: (
    state: SettingsReduxState,
    action: ReduxAction<GroupData[]>,
  ) => ({
    ...state,
    group: {
      ...state.group,
      loadingStates: {
        ...state.group.loadingStates,
        isFetchingGroups: false,
      },
      list: action.payload,
    },
  }),
  [ReduxActionErrorTypes.FETCH_USER_GROUPS_ERROR]: (
    state: SettingsReduxState,
  ) => ({
    ...state,
    group: {
      ...state.group,
      loadingStates: {
        ...state.group.loadingStates,
        isFetchingGroups: false,
      },
    },
  }),
  [ReduxActionTypes.FETCH_USER_GROUP_DETAIL_INIT]: (
    state: SettingsReduxState,
  ) => ({
    ...state,
    group: {
      ...state.group,
      loadingStates: {
        ...state.group.loadingStates,
        isFetchingGroupDetail: true,
      },
    },
  }),
  [ReduxActionTypes.FETCH_USER_GROUP_DETAIL_SUCCESS]: (
    state: SettingsReduxState,
    action: ReduxAction<GroupDetailData>,
  ) => ({
    ...state,
    group: {
      ...state.group,
      loadingStates: {
        ...state.group.loadingStates,
        isFetchingGroupDetail: false,
      },
      detail: action.payload,
    },
  }),
  [ReduxActionErrorTypes.FETCH_USER_GROUP_DETAIL_ERROR]: (
    state: SettingsReduxState,
  ) => ({
    ...state,
    group: {
      ...state.group,
      loadingStates: {
        ...state.group.loadingStates,
        isFetchingGroupDetail: false,
      },
    },
  }),
  [ReduxActionTypes.CREATE_USER_GROUP]: (state: SettingsReduxState) => ({
    ...state,
    group: {
      ...state.group,
      loadingStates: {
        ...state.group.loadingStates,
        isCreatingGroup: true,
      },
    },
  }),
  [ReduxActionTypes.CREATE_USER_GROUP_SUCCESS]: (
    state: SettingsReduxState,
  ) => ({
    ...state,
    group: {
      ...state.group,
      loadingStates: {
        ...state.group.loadingStates,
        isCreatingGroup: false,
      },
    },
  }),
  [ReduxActionErrorTypes.CREATE_USER_GROUP_ERROR]: (
    state: SettingsReduxState,
  ) => ({
    ...state,
    group: {
      ...state.group,
      loadingStates: {
        ...state.group.loadingStates,
        isCreatingGroup: false,
      },
    },
  }),
  [ReduxActionTypes.DELETE_USER_GROUP]: (state: SettingsReduxState) => ({
    ...state,
    group: {
      ...state.group,
      loadingStates: {
        ...state.group.loadingStates,
        isDeletingGroup: true,
      },
    },
  }),
  [ReduxActionTypes.DELETE_USER_GROUP_SUCCESS]: (
    state: SettingsReduxState,
  ) => ({
    ...state,
    group: {
      ...state.group,
      loadingStates: {
        ...state.group.loadingStates,
        isDeletingGroup: false,
      },
    },
  }),
  [ReduxActionErrorTypes.DELETE_USER_GROUP_ERROR]: (
    state: SettingsReduxState,
  ) => ({
    ...state,
    group: {
      ...state.group,
      loadingStates: {
        ...state.group.loadingStates,
        isDeletingGroup: false,
      },
    },
  }),
  [ReduxActionTypes.UPDATE_USER_GROUP]: (state: SettingsReduxState) => ({
    ...state,
    group: {
      ...state.group,
      loadingStates: {
        ...state.group.loadingStates,
        isUpdatingGroup: true,
      },
    },
  }),
  [ReduxActionTypes.UPDATE_USER_GROUP_SUCCESS]: (
    state: SettingsReduxState,
    action: ReduxAction<GroupData>,
  ) => ({
    ...state,
    group: {
      ...state.group,
      loadingStates: {
        ...state.group.loadingStates,
        isUpdatingGroup: false,
      },
      detail: {
        ...state.group.detail,
        name: action.payload.name,
        description: action.payload.description,
      },
    },
  }),
  [ReduxActionErrorTypes.UPDATE_USER_GROUP_ERROR]: (
    state: SettingsReduxState,
  ) => ({
    ...state,
    group: {
      ...state.group,
      loadingStates: {
        ...state.group.loadingStates,
        isUpdatingGroup: false,
      },
    },
  }),
  [ReduxActionTypes.USER_GROUP_INVITE_USER_SUCCESS]: (
    state: SettingsReduxState,
    action: ReduxAction<GroupDetailData>,
  ) => ({
    ...state,
    group: {
      ...state.group,
      detail: action.payload,
      userTeamsUpdateKey: state.group.userTeamsUpdateKey + 1,
    },
  }),
  [ReduxActionTypes.USER_GROUP_REMOVE_USER_SUCCESS]: (
    state: SettingsReduxState,
    action: ReduxAction<GroupDetailData>,
  ) => ({
    ...state,
    group: {
      ...state.group,
      detail: action.payload,
    },
  }),
  [ReduxActionTypes.USER_UPDATE_GROUPS]: (state: SettingsReduxState) => ({
    ...state,
    group: {
      ...state.group,
      loadingStates: {
        ...state.group.loadingStates,
        isSavingUserTeams: true,
      },
    },
  }),
  [ReduxActionTypes.USER_UPDATE_GROUPS_SUCCESS]: (
    state: SettingsReduxState,
  ) => ({
    ...state,
    group: {
      ...state.group,
      loadingStates: {
        ...state.group.loadingStates,
        isSavingUserTeams: false,
      },
      userTeamsUpdateKey: state.group.userTeamsUpdateKey + 1,
    },
  }),
  [ReduxActionErrorTypes.USER_UPDATE_GROUPS_ERROR]: (
    state: SettingsReduxState,
  ) => ({
    ...state,
    group: {
      ...state.group,
      loadingStates: {
        ...state.group.loadingStates,
        isSavingUserTeams: false,
      },
    },
  }),
  [ReduxActionTypes.ROLES_ASSOCIATE]: (state: SettingsReduxState) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isAssociatingRoles: true,
      },
    },
  }),
  [ReduxActionTypes.ROLES_ASSOCIATE_SUCCESS]: (state: SettingsReduxState) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isAssociatingRoles: false,
      },
      associateUpdateKey: state.role.associateUpdateKey + 1,
    },
  }),
  [ReduxActionErrorTypes.ROLES_ASSOCIATE_ERROR]: (
    state: SettingsReduxState,
  ) => ({
    ...state,
    role: {
      ...state.role,
      loadingStates: {
        ...state.role.loadingStates,
        isAssociatingRoles: false,
      },
    },
  }),
};

export default createReducer(initialState, handlers);
