import Api from "./Api";
import type { AxiosPromise } from "axios";
import type { ApiResponse } from "api/ApiResponses";

import type { InitConsolidatedApi } from "sagas/InitSagas";

class ModuleEditorLoadApi extends Api {
  static url = "/v1/module/";

  static async getModuleEditorLoadDataView(params: {
    moduleId: string;
    defaultPageId?: string;
  }): Promise<AxiosPromise<ApiResponse<InitConsolidatedApi>>> {
    return Api.get(ModuleEditorLoadApi.url + "/view", params);
  }
  static async getModuleEditorLoadDataEdit(params: {
    moduleId: string;
    defaultPageId?: string;
  }): Promise<AxiosPromise<ApiResponse<InitConsolidatedApi>>> {
    return Api.get(ModuleEditorLoadApi.url + `${params.moduleId}/edit`);
  }
}

export default ModuleEditorLoadApi;
